import { setup } from '../config/'

export function getRemoteSocket( that, token ) {

    var webSocket = new WebSocket( setup.remoteSocket + '?token=' + token );

    webSocket.onopen = () => {
        console.log( '.:: RMT webSocket ::: onOpen ::.' );
        that.setState( { WSReady : true });
    }

    webSocket.onclose = () => {
        console.log( '.:: RMT webSocket ::: onClose ::.' );
        that.setState( { WSReady : false });
    }

    webSocket.onerror = ( err ) => {
        that.setState({ WSError : true });
        console.log( '.:: RMT webSocket webSocket ::: onError ::.' );
        webSocket.close();
    };

    return webSocket;
}

export function getDisplaySocket( that, token ) {

    var webSocket = new WebSocket( setup.displaySocket + '?token=' + token );

    webSocket.onopen = () => {
        console.log( '.:: DSP webSocket ::: onOpen ::.' );
    }

    webSocket.onmessage = ( evt ) => {

        const message = JSON.parse( evt.data );
        
        console.log( '.:: DSP webSocket ::: onMessage ::.', evt.data );

        if ( message.method === 'dspVideoWall' ) {
            if ( message.params[0] === that.state.currentWPID ) {    
                var autoPlay = ( message.params[0] === 'kiosk' ) ? true : false;
                var slide = ( message.params[1] ) ? message.params[1] : 0;   
                that.setState({ 
                    isHidden        : false,
                    isPlayBack      : autoPlay,
                    iPadReady       : false,
                    isVideoReady    : false,
                    isVideoEnd      : false,
                }, () => { that.setLayout( slide )});
            } else {
                var autoPlay = ( message.params[0] === 'kiosk' ) ? true : false;                    
                that.setState({ 
                    isHidden        : false,
                    isPlayBack      : autoPlay,
                    iPadReady       : false,
                    isVideoReady    : false,
                    isVideoEnd      : false,
                }, () => { that.getData( message.params[0] )});
                ;
            }
        } else if ( message.method === 'triggerContent' ) {
            that.setState({ isHidden : message.params[0] });        
        } else if ( message.method === 'videoPlayBack' ) {
            that.setState({ isPlayBack : message.params[0] });        
        } else if ( message.method === 'controlMashup' ) {
            that.setState({
                slideState : {
                    slider1Pos      : message.params[0],
                    slider2Pos      : message.params[1],
                    slider3Pos      : message.params[2],
                    slider4Pos      : message.params[3],
                    expandSlide1    : message.params[4],
                    expandSlide2    : message.params[5],
                    expandSlide3    : message.params[6],
                    expandSlide4    : message.params[7],    
                }
            });        
        }
    
    }

    webSocket.onclose = () => {
        console.log( '.:: DSP webSocket ::: onClose ::.' );
    }

    webSocket.onerror = ( err ) => {
        that.setState({ WSError : true });
        console.log( '.:: DSP webSocket webSocket ::: onError ::.' );
        webSocket.close();
    };
 
    return webSocket;
   
}

export function onLoadScreenSendMessage( that, status ) {  
    const { webSocketR } = that.state
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'screenFeedBack', [ status ] ]
    }
    webSocketR.send( JSON.stringify( data ) );
}

export function setVideoIsReadySendmessage( that, videoReady ) {
    const { webSocketR } = that.state
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'videoIsReady', [ videoReady ] ]
    }
    webSocketR.send( JSON.stringify( data ) );
}

export function setVideoIsEndSendmessage( that, videoEnd ) {
    const { webSocketR } = that.state
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'videoIsEnd', [ videoEnd ] ]
    }
    webSocketR.send( JSON.stringify( data ) );
}

export function setVideoBufferSatusSendmessage( that, videoEnd ) {
    const { webSocketR } = that.state
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'videoIsBuffering', [ videoEnd ] ]
    }
    webSocketR.send( JSON.stringify( data ) );
}






