import React, {Component} from 'react';
import _ from 'lodash'
import { ScreenView } from '../pages';
import { getScreensInfo, getWebSocketTokenDisplay } from '../../API'
import { getDisplaySocket, getRemoteSocket, onLoadScreenSendMessage, setVideoIsReadySendmessage, setVideoIsEndSendmessage, setVideoBufferSatusSendmessage } from '../../WS';

import './App.css';

class App extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            iPadReady       : false,
            kioskMode       : false,
            kioskMax        : 0,
            kioskSlide      : 0,
            viewLayoutKeyS1 : 0,
            viewLayoutKeyS2 : 0,
            currentScreen   : 1,
            currentView     : 1,
            viewScreen1     : 'active',
            viewScreen2     : 'unactive',
            isFetching      : false,
            isReady         : true,
            screensArray    : [{'layout':'loader','modules':[{'template' :'load'}]}],
            currentWPID     : false,
            WSToken         : false,
            webSocket       : false,
            webSocketR      : false,
            WSReady         : false,
            isHidden        : false,
            isPlayBack      : true,
            isVideoReady    : false,
            isVideoEnd      : false,
            slideState      : {
                slider1Pos      : 0,
                slider2Pos      : 0,
                slider3Pos      : 0,
                slider4Pos      : 0,
                expandSlide1    : false,
                expandSlide2    : false,
                expandSlide3    : false,
                expandSlide4    : false,    
            }
        };

        this.getData                = this.getData.bind( this );
        this.onLoadScreen           = this.onLoadScreen.bind( this );
        this.setIpadStatus          = this.setIpadStatus.bind( this );
        this.setVideoIsReady        = this.setVideoIsReady.bind( this );
        this.setVideoIsEnd          = this.setVideoIsEnd.bind( this );
        this.setVideoBufferSatus    = this.setVideoBufferSatus.bind( this );

        this.loadCount          = 0;
        this.loadableCount      = 1;
    }

    getDataModeRemote( response, wpid ) {
        clearInterval( this.state.kioskMode ); 
        this.setState({ 
            screensArray    : response.data.screens,
            currentWPID     : wpid,
            viewLayoutKeyS1 : 0,
            viewLayoutKeyS2 : 0,
            kioskMode       : false,
            kioskMax        : 0
        }, () => { this.setLayout( 0 ) } );
    }
    
    setLayoutOnKiosk( ck1, ck2, slide ) {
        if ( ck1 !== ck2 ) {
            this.setLayout( slide );
        }
    }

    setVideoIsReady() {
        this.setState({
            isVideoReady : true,
        }, () => { setVideoIsReadySendmessage( this, this.state.isVideoReady ) } )
    }
    
    setVideoIsEnd() {
        this.setState({
            isVideoEnd : true,
        }, () => { setVideoIsEndSendmessage( this, this.state.isVideoEnd ) } )
    }

    setVideoBufferSatus( status ) {
        this.setState({
            isVideoBuffering : status,
        }, () => { setVideoBufferSatusSendmessage( this, this.state.isVideoBuffering ) } )
    }
    
    setLayoutKiosk() {
        let prevSlide = this.state.kioskSlide;
        let slide = ( this.state.kioskSlide + 1 < this.state.kioskMax ) ? this.state.kioskSlide + 1 : 0;
        let ck1 = this.state.screensArray[ prevSlide ].checkrp;
        let ck2 = this.state.screensArray[ slide ].checkrp;
        this.setState({
            kioskSlide : slide
        }, () => { this.setLayoutOnKiosk( ck1, ck2, slide ) } );
    }
    
    getDataModeKiok( response, wpid ) {
        clearInterval( this.state.kioskMode ); 
        this.setState({ 
            screensArray    : response.data.screens,
            currentWPID     : wpid,
            kioskMax        : response.data.screens.length,
            kioskMode       : setInterval( () => { this.setLayoutKiosk() } , 5000 ),
            viewLayoutKeyS1 : 0,
            viewLayoutKeyS2 : 0,
            isPlayBack      : true,
        });
    }

    getData( wpid ) {
        this.loadCount = 0;
        this.loadableCount = 1;
        this.setState({ 
            viewScreen1     : 'unactive',
            viewScreen2     : 'unactive',
            isHidden        : false,
        });     
        getScreensInfo( wpid ).then( ( response ) => {
            if ( wpid === 'kiosk' ) {
                this.getDataModeKiok( response, wpid );
            } else {
                this.getDataModeRemote( response, wpid );
            }
        });
    }

    componentDidMount() {
        this.getData( 'kiosk' );
        getWebSocketTokenDisplay().then( ( response ) => {   
            this.setState({ 
                WSToken     : response.data.token,
                webSocket   : getDisplaySocket( this, response.data.token ),
                webSocketR  : getRemoteSocket( this, response.data.token )
            });
        });

    }

    setLayout( layout ) {
        var content = this.state.screensArray;
        if ( this.state.isReady ) {
            this.loadCount = 0;
            this.loadableCount = _.size( _.filter( _.get( content, `[${layout}].modules`, [] ), v => v.template === 'image' || v.template === 'slide' || v.template === 'video' || v.template === 'unsafe-html' ) )
            if ( this.state.currentScreen === 1 ) {
                this.setState({
                    viewLayoutKeyS2 : layout,
                    currentScreen   : 2,
                    isFetching      : true,
                    isReady         : false,
                });
                setTimeout(() => {
                    this.setState({
                        viewLayoutKeyS1 : -1,
                        isReady         : true,
                    });  
                }, 2000 );
            } else {
                this.setState({
                    viewLayoutKeyS1 : layout,
                    currentScreen   : 1,
                    isFetching      : true,
                    isReady         : false,
                });     
                setTimeout(() => {
                    this.setState({
                        viewLayoutKeyS2 : -1,
                        isReady         : true,
                    });  
                    if ( this.state.WSReady ) {
                        // console.log( 'IPAD ESTOY LISTA DE MENTIRA!!!' );                 
                    }
                }, 2000 );
            }
        }
    }
    
    setIpadStatus() {
        if ( this.state.WSReady ) {
            onLoadScreenSendMessage( this, this.state.iPadReady ); 
        }
    }
    
    onLoadScreen() {
        this.loadCount = this.loadCount + 1;
        if(this.loadCount === this.loadableCount) {
            if ( this.state.currentScreen === 1 ) {
                this.setState({
                    viewScreen1     : 'active',
                    viewScreen2     : 'unactive',
                    currentView     : 1,
                    isFetching      : false,
                    iPadReady       : true
                }, () => { this.setIpadStatus() });    
            } else {
                this.setState({
                    viewScreen1     : 'unactive',
                    viewScreen2     : 'active',
                    currentView     : 2,
                    isFetching      : false,
                    iPadReady       : true
                }, () => { this.setIpadStatus() }); 
            }
        }
    }



        
    render() {  
        
        var { viewLayoutKeyS1, viewLayoutKeyS2, viewScreen1, viewScreen2, screensArray, isHidden, isPlayBack, slideState } = this.state;
        var hiddeableHidden = ( isHidden ) ? 'overlayHidden' : '';
        
        console.log( 'isPlayBack', isPlayBack );
        
        return(
            <div>
                <ScreenView 
                    content={ screensArray[ viewLayoutKeyS1 ] }
                    addClass={ 'screens screen-one ts ts-ease-in ts-slow ' + viewScreen1 }
                    
                    onLoadCall={ this.onLoadScreen } 
                    onVideoReady={ this.setVideoIsReady }
                    onVideoEnd={ this.setVideoIsEnd }
                    onBuffer={ this.setVideoBufferSatus }
                    
                    hiddeableHidden={ hiddeableHidden } 
                    isPlayBack={ isPlayBack } 
                    slideState={ slideState }
                    
                    webSocketR={ this.state.webSocketR }
                    WSReady={ this.state.WSReady }
                />
                <ScreenView 
                    content={ screensArray[ viewLayoutKeyS2 ] }
                    addClass={ 'screens screen-two ts ts-ease-in ts-slow ' + viewScreen2 }
                    
                    onLoadCall={ this.onLoadScreen } 
                    onVideoReady={ this.setVideoIsReady }
                    onVideoEnd={ this.setVideoIsEnd }
                    onBuffer={ this.setVideoBufferSatus }

                    hiddeableHidden={ hiddeableHidden } 
                    isPlayBack={ isPlayBack } 
                    slideState={ slideState }
                    
                    webSocketR={ this.state.webSocketR }
                    WSReady={ this.state.WSReady }
                />
            </div>
        )
    }
}

export default App;