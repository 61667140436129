export var setup = {
    'maxIdletime'       : 60,
    'APIBaseUrl'        : 'https://service.gcadscreens.com',
    'APIPrefix'         : '/wp-json',
    'WSDsiplayTokenUrl' : 'https://service.gcadscreens.com/jwt/DISPLAY',
    'WSRemoteTokenUrl'  : 'https://service.gcadscreens.com/jwt/REMOTE',
    'remoteSocket'      : 'wss://service.gcadscreens.com/ws/rpc',
    'displaySocket'     : 'wss://service.gcadscreens.com/ws/raw',
    'originWP'          : 'service.gcadscreens.com',
    'originCDN'         : 'daohhawy174np.cloudfront.net',
}