import axios from 'axios'
import { setup } from '../config/'

export const API_PREFIX = setup.APIPrefix;
export const REMOTE_TOKEN = setup.WSRemoteTokenUrl;
export const DISPLAY_TOKEN = setup.WSDsiplayTokenUrl;

export const instance = axios.create({
    baseURL : setup.APIBaseUrl,
    headers : {
        'Content-Type' : 'application/json'
    }
})

const successCallBack = response => response

const errorCallBack = error => {

const {
    response : { status }
} = error

if ( status === 401 || status === 403 ) {
        instance.defaults.headers.common['Authorization'] = ''
    } else {
        return Promise.reject(error)
    }
}

instance.interceptors.response.use(successCallBack, errorCallBack)

export function addTokenHeader(token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export function addLangToRequest(lang) {
    instance.defaults.headers.common['X-LOCALE'] = lang
}

export function removeTokenHeader() {
    instance.defaults.headers.common['Authorization'] = ''
}

export function get(url) {
    return instance.get(url)
}

export function post(url, data, config) {
    return instance.post(url, data, config)
}

export function put(url, data, config) {
    return instance.put(url, data, config)
}

export function patch(url, data) {
    return instance.patch(url, data)
}

export function remove(url) {
    return instance.delete(url)
}